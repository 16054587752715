<template>
  <div id="app">
    <div id="header">
      <img class="logo sm:w-60 w-36 cursor-pointer" src="@/assets/logo.png" @click="goHome"/>
      <div class="operation text-lg">
        <span class="text-xs sm:text-sm" @click="goContact">
          {{ $t("h.Nav.ContactUs") }}
        </span>
        <div class="changeLang">
          <el-dropdown trigger="click" @command="changeLang">
            <span class="text-xs sm:text-sm">{{ $t("h.Nav.ChangeLang") }}</span>
            <el-dropdown-menu slot="dropdown">
              <!--              <el-dropdown-item command="zh">简体中文</el-dropdown-item>-->
              <el-dropdown-item command="en">English</el-dropdown-item>
              <el-dropdown-item command="ru">Русский язык</el-dropdown-item>
              <el-dropdown-item command="ar">بالعربية</el-dropdown-item>
              <el-dropdown-item command="fa">فارسی</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <router-view/>
    <div id="footer" class="w-full p-4 flex flex-col justify-center items-center gap-3">
      <div class="title">{{ $t("h.Footer.Title") }}</div>
      <div class="subheading">{{ $t("h.Footer.Subheading") }}</div>
      <div class="button cursor-pointer" @click="goContact">{{ $t("h.Footer.BtnText") }}</div>
    </div>
  </div>
</template>
<script>
import utils from "@/utils/utils";

export default {
  name: 'App',
  data() {
    return {}
  },
  methods: {
    changeLang(opt) {
      utils.setLocalLangKey(opt)
      this.$i18n.locale = opt
      window.location.reload()
    },
    goHome() {
      this.$router.push({path: '/'})
    },
    goContact() {
      this.$router.push({path: '/contact'})
    }
  },
  created() {
  },
  mounted() {
  }
}
</script>
<style lang="less">
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  font-family: sans-serif;
}

//*::-webkit-scrollbar {
//  display: none;
//}

#app {
  margin: 0;
  padding: 0;
  max-width: 100vw;
  min-height: 100vh;
  background-color: #f0f0f0;
}

#header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: white;

  .logo {
    //width: 25%;
  }

  .operation {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
}

#footer {
  text-align: center;

  .title {
    font-size: 24px;
    color: #2A2A2A;
  }

  .subheading {
    font-size: 16px;
  }

  .button {
    color: #ffffff;
    background-color: #F90;
    font-size: 14px;
    padding: 14px 20px;
    border-radius: 5px;
  }
}

</style>