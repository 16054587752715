export const h = {
    Nav: {
        ContactUs: "联系我们",
        ChangeLang: "切换语言",
    },
    Footer: {
        Title: "成为 ygpetro.com 上的卖家",
        Subheading: "我们致力于帮助您吸引更多买家并销售更多设备！",
        BtnText: "现在就开始"
    },
    HomePage: {
        SearchView: {
            Title: "ygpetro.com",
            Subheading: "钻井和油田专业人士的1个市场！",
            SearchInputPlaceholder: "你在找什么..."
        },
        ProductRecommend: {
            Title: "特色设备"
        },
        Container: {
            Title: "产品列表"
        },
        ViewDetailsBtn: "查看详情",
        CreateTime: "发布时间",
    },
    DetailPage: {
        RecommendListTitle: "推荐产品",
        ContactFormLabel: {
            Name: "您的姓名",
            Email: "电子邮件地址",
            Phone: "电话号码",
            Message: "留言",
            MessagePlaceholder: "在此处输入...",
            Send: "提交以接收电子邮件回复"
        },
        ContactFormValidateMessage: {
            Name: "请输入您的姓名",
            Email: "请输入您的电子邮件地址",
            Phone: "请输入您的电话号码",
            Message: "请输入留言",
        },
        ContactFormSubmitMessage: "提交成功"
    },
}