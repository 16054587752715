export const h = {
    Nav: {
        ContactUs: "با ما تماس بگیرید",
        ChangeLang: "تغییر زبان",
    },
    Footer: {
        Title: "یک فروشنده در ygpetro.com شوید",
        Subheading: "ما به کمک شما در جذب خریداران بیشتر و فروش تجهیزات بیشتر می پردازیم!",
        BtnText: "اکنون شروع کنید"
    },
    HomePage: {
        SearchView: {
            Title: "ygpetro.com",
            Subheading: "یک بازار برای متخصصان چاهکشی و نفت!",
            SearchInputPlaceholder: "چه چیز را دنبال می کنید..."
        },
        ProductRecommend: {
            Title: "تجهیزات ویژه"
        },
        Container: {
            Title: "لیست محصولات"
        },
        ViewDetailsBtn: "نمایش جزئیات",
        CreateTime: "زمان ایجاد",
    },
    DetailPage: {
        RecommendListTitle: "محصولات پیشنهاد",
        ContactFormLabel: {
            Name: "نام شما",
            Email: "نشانی ایمیل",
            Phone: "شماره تلفن",
            Message: "پیام",
            MessagePlaceholder: "اینجا نویس...",
            Send: "فرستادن پاسخ نامه"
        },
        ContactFormValidateMessage: {
            Name: "لطفا نام خود را وارد کنید",
            Email: "لطفاً آدرس ایمیل خود را وارد کنید",
            Phone: "لطفا شماره تلفنتون را وارد کنید",
            Message: "لطفا پیام را وارد کنید",
        },
        ContactFormSubmitMessage: "تسلیم موفقیت بود"
    },
}
