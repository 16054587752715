export const h = {
    Nav: {
        ContactUs: "اتصل بنا",
        ChangeLang: "تبديل اللغة",
    },
    Footer: {
        Title: "تصبح البائع على ygpetro.com",
        Subheading: "ونحن ملتزمون مساعدتك على جذب المزيد من المشترين وبيع المزيد من المعدات !",
        BtnText: "تبدأ الآن"
    },
    HomePage: {
        SearchView: {
            Title: "ygpetro.com",
            Subheading: "1 سوق لأنّ يحفر وحقل نفط محترفة !",
            SearchInputPlaceholder: ". . . ما الذي تبحث عنه"
        },
        ProductRecommend: {
            Title: "معدات خاصة"
        },
        Container: {
            Title: "قائمة المنتجات"
        },
        ViewDetailsBtn: "عرض التفاصيل",
        CreateTime: "خلق الوقت",
    },
    DetailPage: {
        RecommendListTitle: "المنتجات الموصى بها",
        ContactFormLabel: {
            Name: "اسمك",
            Email: "عنوان البريد الإلكتروني",
            Phone: "رقم الهاتف",
            Message: "اترك رسالة",
            MessagePlaceholder: "اكتب هنا . . .",
            Send: "يقدم الرد على البريد الإلكتروني"
        },
        ContactFormValidateMessage: {
            Name: "من فضلك أدخل اسمك",
            Email: "الرجاء إدخال عنوان البريد الإلكتروني الخاص بك",
            Phone: "من فضلك أدخل رقم هاتفك",
            Message: "الرجاء إدخال رسالة",
        },
        ContactFormSubmitMessage: "يقدم بنجاح"
    },
}