export const h = {
    Nav: {
        ContactUs: "Contact Us",
        ChangeLang: "Switch Language",
    },
    Footer: {
        Title: "Become a Seller on ygpetro.com",
        Subheading: "We are Committed to helping you Reach More Buyers and Sell More Equipment!",
        BtnText: " Get Started Now"
    },
    HomePage: {
        SearchView: {
            Title: "ygpetro.com",
            Subheading: "The #1 Marketplace for Drilling & Oilfield Professionals!",
            SearchInputPlaceholder: "What Are You Looking For..."
        },
        ProductRecommend: {
            Title: "Featured Equipment"
        },
        Container: {
            Title: "Product List"
        },
        ViewDetailsBtn: "View Details",
        CreateTime: "Create time",
    },
    DetailPage: {
        RecommendListTitle: "Recommended products",
        ContactFormLabel: {
            Name: "Your Name",
            Email: "Email Address",
            Phone: "Phone Number",
            Message: "Message",
            MessagePlaceholder: "Type here...",
            Send: "Submit for Email Reply"
        },
        ContactFormValidateMessage: {
            Name: "Please enter your name",
            Email: "Please enter your email address",
            Phone: "Please enter your phone number",
            Message: "Please enter message",
        },
        ContactFormSubmitMessage: "The submission was successful"
    },
}