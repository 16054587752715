export const h = {
    Nav: {
        ContactUs: "Свяжитесь с нами",
        ChangeLang: "Переключить язык",
    },
    Footer: {
        Title: "Стать продавцом на Ygpetro.com",
        Subheading: "Мы стремимся помочь вам привлечь больше покупателей и продать больше оборудования!",
        BtnText: "Начнем прямо сейчас."
    },
    HomePage: {
        SearchView: {
            Title: "ygpetro.com",
            Subheading: "Один рынок для буровых и нефтяных специалистов!",
            SearchInputPlaceholder: "Что ты ищешь?"
        },
        ProductRecommend: {
            Title: "Специальное оборудование"
        },
        Container: {
            Title: "Список продуктов"
        },
        ViewDetailsBtn: "Смотрите подробную информацию",
        CreateTime: "Создать время",
    },
    DetailPage: {
        RecommendListTitle: "Рекомендуемые продукты",
        ContactFormLabel: {
            Name: "Имя твое",
            Email: "Адрес электронной почты",
            Phone: "Номер телефона",
            Message: "Сообщение",
            MessagePlaceholder: "Введите здесь....",
            Send: "Отправить ответ по электронной почте"
        },
        ContactFormValidateMessage: {
            Name: "Введите ваше имя",
            Email: "Пожалуйста, введите свой адрес электронной почты",
            Phone: "Пожалуйста, введите свой номер телефона",
            Message: "Пожалуйста, введите сообщение.",
        },
        ContactFormSubmitMessage: "Успешное представление"
    },
}