class Utils {
    baseUrl = "http://www.ygpetro.com"
    timeOut = 3600000

    getLocalLangKeyName() {
        return "localLang"
    }

    setLocalLangKey(langKey) {
        localStorage.setItem(this.getLocalLangKeyName(), langKey)
    }
}

export default new Utils()
